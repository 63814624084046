import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient ,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateStripeCustomerRequest, FreeTrialRegisterRequest, Loginrequest, RegisterRequestModel } from '../models/loginrequest';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AccountService {
  // authorisepaymentApi(sendRequest: () => void) {
  //   throw new Error('Method not implemented.');
  // }
  // private apiUrl = 'https://api.authorize.net/xml/v1/request.api';
  registerModel :RegisterRequestModel= new RegisterRequestModel();
  model: BehaviorSubject<RegisterRequestModel>;
  

  constructor(private http : HttpClient  ) { 
    this.model  = new BehaviorSubject(this.registerModel);

  }

  manageData() {
    this.model.next(this.registerModel);
  }

  loginService(login:Loginrequest):Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    var body= JSON.stringify(login);
    var result=this.http.post(environment.baseUrl+environment.loginApi,login,options).pipe(map((response:any)=> response))
    return result;
  }

  loggedIn(){
     return !!localStorage.getItem('token');
  }

  checkUserExist(email:string):Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    return  this.http.get(environment.baseUrl + environment.isClientExistApi +email,options);
  }

  sendOTPForEmailVerification(email:string) : Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    return this.http.post(environment.baseUrl + environment.sendOtpApi +email,options);
  }

  verifyOtp(email:string,otp:string):Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    var requestModel:any={
      Email:email,
      Otp:otp
    }
    var body = JSON.stringify(requestModel);
    return this.http.post(environment.baseUrl + environment.verifyOtpApi , body ,options);
  }

  sendOTPForgetPassword(email:string) : Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    return this.http.post(environment.baseUrl + environment.forgetPasswordOtpApi + email , options);
  }

 registerUser(requestModel:RegisterRequestModel):Observable<any>{
  var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
  var body = JSON.stringify(requestModel);
  return this.http.post(environment.baseUrl +environment.createMultiClientApi  , body , options);
  }


  getSupsriptionPrice():Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    return this.http.get(environment.baseUrl + environment.getSubscriptionPrice, options);
  }
//todo3jan23

getuserSupsriptionPrice():Observable<any>{
  var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
  return this.http.get(environment.baseUrl + environment. getUserSubscriptionPrice, options);
}
  registerFreeTrialUser(requestModel:FreeTrialRegisterRequest):Observable<any>{
    var options= {headers :new HttpHeaders({'Content-Type':'application/json'})};
    var body = JSON.stringify(requestModel);
    return this.http.post(environment.baseUrl + environment.createMultiClientApi , body , options);
  }

  createStripeCustomer(requestModel:CreateStripeCustomerRequest):Observable<any>{
    var options= {
      headers :new HttpHeaders({'Content-Type':'application/json'})
    };
    var body = JSON.stringify(requestModel);
    
    return this.http.post(environment.baseUrl + environment.createStripeCustomerApi , body , options);

  }
     //Authorised .net 
    /// <summary>
      /// Authorize the merchant detail and if merchant is valid process the credit card.
       /// </summary>
       /// <param name="objAuthorizeNetRequest"></param>
      /// <returns></returns>
  
  CallAuthorizeNetMethod(sendrequest:any):Observable<any>{
    var options= {
      headers :new HttpHeaders({'Content-Type':'application/json'})
    };
    var body = JSON.stringify(sendrequest);
    
   // return this.http.post('https://api.authorize.net/xml/v1/request.api' , body , options);//for Production
    return this.http.post('https://apitest.authorize.net/xml/v1/request.api' , body , options);//For staging


  }
  
  }
 
  





     
 
 